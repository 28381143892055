import FacebookRounded from '@mui/icons-material/FacebookRounded';
import LinkIcon from '@mui/icons-material/Link';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Pinterest from '@mui/icons-material/Pinterest';
import Reddit from '@mui/icons-material/Reddit';
import Twitter from '@mui/icons-material/Twitter';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system/styleFunctionSx';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { Avatar } from '../components/Avatar';
import Bio from '../components/Bio';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { SmallPostCard } from '../components/SmallPostCard';
import { FetchPostInfoQuery } from '../graphqlTypes';
import { tagSlug } from '../utils';
import { Theme } from '@mui/material/styles';

interface PostPageProps {
	data: FetchPostInfoQuery,
	pageContext: {
		id: string,
		nextId?: string,
		previousId?: string,
	},
	location?: Location, // Not available during SSR
}

const usePostStyles = makeStyles((theme: Theme) => ({
	postSection: {
		"& sup": {
			fontSize: "0.8rem",
			fontWeight: "bold",
			// color: `${theme.palette.primary.main}`,
			"& a": {
				textDecoration: "none",
			}
		},
		"& > .footnotes": {
			"& li": {
				...theme.typography.body2,
			},
			"& ol": {
				paddingInline: "0.5rem",
				listStylePosition: "inside",
			},
		},
	}
}));

const Post: React.FC<PostPageProps> = ({
	data: { site, mdx: post, previous, next },
	pageContext: { id },
	location,
}) => {
	const postStyles = usePostStyles()

	if (!post || !site) {
		throw new Error(`post (id=${id}) not found!`)
	}

	const otherArticles = [previous, next].filter((article) => !!article)

	return (

		<Layout>
			<Seo
				title={post.frontmatter.title}
				description={post.frontmatter.description.short}
				image={post.frontmatter.seoImage?.childImageSharp?.resize}
				pathname={location?.pathname}
			/>

			<article
				className="blog-post"
				itemScope
				itemType="http://schema.org/Article"
			>
				<header>
					{/* Tags */}
					<Box display="flex" gap="0.5rem" flexWrap="wrap" my="2rem">
						{post.frontmatter.tags.map(tag => (
							<Link key={tag} to={tagSlug(tag)} style={{ textDecoration: "none", color: "inherit" }}>
								<Chip
									variant="outlined"
									color="primary"
									label={tag}
									sx={{ cursor: "pointer" }}
								/>
							</Link>
						))}
					</Box>
					<Typography
						component="h1"
						fontWeight="bold"
						itemProp="headline"
						variant="h2"
						sx={{ mb: 4 }}
					>
						{post.frontmatter.title}
					</Typography>
					<Typography
						color="text.secondary"
						component="div"
						variant="h6"
						sx={{ mb: 4 }}
					>
						{post.frontmatter.description.long || post.frontmatter.description.short}
					</Typography>

					{/* Avatar */}
					<Avatar name={site.siteMetadata.author.name} infoText={post.frontmatter.date} showSocials />

					{/* Banner */}
					{post.frontmatter.banner?.childImageSharp?.gatsbyImageData && (
						<Box sx={{ textAlign: "center", my: "4rem" }}>
							<GatsbyImage
								image={post.frontmatter.banner.childImageSharp.gatsbyImageData}
								alt={post.frontmatter.tags.join(', ')}
								imgStyle={{ "borderRadius": "1.5rem" }}
							/>
						</Box>
					)}
				</header>
				<Box
					component="section"
					itemProp="articleBody"
					className={postStyles.postSection}
					maxWidth="md"
					sx={{
						my: "2rem",
						mx: "auto",
					}}
				>
					{/* <TableOfContent headings={post.headings || []}/> */}
					<MDXRenderer>{post.body}</MDXRenderer>
				</Box>
				<footer>
					{location && <ShareArticleBox url={location.href} title={post.frontmatter.title} sx={{ my: "4rem" }} />}
					<Box maxWidth="sm" mx="auto" my="3rem">
						<Typography variant="h5" fontWeight="bold" gutterBottom>Written by</Typography>
						<Bio />
					</Box>
				</footer>
			</article>
			{otherArticles.length > 0 && (
				<>
					<Typography variant="h4" fontWeight="bold" my="2rem">Continue reading 👇</Typography>
					{[previous, next].map((article, index) => (
						article && (
							<SmallPostCard
								key={index}
								post={{
									title: article.frontmatter.title,
									description: article.frontmatter.description.short,
									tags: article.frontmatter.tags,
									banner: article.frontmatter.banner?.childImageSharp?.gatsbyImageData,
									date: article.frontmatter.date,
									slug: article.fields.defaultSlug,
								}}
								sx={{ my: "3rem" }}
							/>
						)
					))}
					<Link to="/blog/tags/all/1" style={{ textDecoration: "none", color: "inherit", textAlign: "right" }}>
						<Typography variant="h6" component="div">📚 More articles</Typography>
					</Link>
				</>
			)}
		</Layout>
	)
}

interface ShareArticleBoxProps {
	url: string,
	title: string,
	sx?: SxProps<Theme>,
}

const ShareArticleBox: React.FC<ShareArticleBoxProps> = ({ url, title, sx }) => {
	const size = "large"
	const urlEncoded = encodeURIComponent(url)
	const titleEncoded = encodeURIComponent(title)

	sx = sx || {}

	return (
		<Box sx={{ textAlign: "center", alignItems: "center", borderRadius: "0.5rem", mx: "auto", ...sx }}>
			<Typography variant="h5" fontWeight="bold" mb="1rem">Share this page! 💖</Typography>
			<Box className="thin-scrollbar" sx={{ display: "flex", gap: "1rem", mx: "auto", maxWidth: "100%", width: "fit-content" }}>
				<Tooltip title="Twitter">
					<a
						href={`http://twitter.com/share?url=${urlEncoded}&text=${encodeURIComponent("Check this out!")}`}
						target="_blank"
						style={{ textDecoration: "none", color: "inherit" }}>
						<Twitter fontSize={size} />

					</a>
				</Tooltip>

				<a
					href={`http://www.facebook.com/share.php?u=${urlEncoded}`}
					target="_blank"
					style={{ textDecoration: "none", color: "inherit" }}>
					<Tooltip title="Facebook">
						<FacebookRounded fontSize={size} />
					</Tooltip>
				</a>

				<a
					href={`https://www.linkedin.com/sharing/share-offsite/?url=${urlEncoded}`}
					target="_blank"
					style={{ textDecoration: "none", color: "inherit" }}>
					<Tooltip title="Linkedin">
						<LinkedIn fontSize={size} />
					</Tooltip>
				</a>

				<a
					href={`https://reddit.com/submit?url=${urlEncoded}&title=${titleEncoded}`}
					target="_blank"
					style={{ textDecoration: "none", color: "inherit" }}>
					<Tooltip title="Reddit">
						<Reddit fontSize={size} />
					</Tooltip>
				</a>

				<a
					href={`http://pinterest.com/pin/create/button/?url=${urlEncoded}`}
					target="_blank"
					style={{ textDecoration: "none", color: "inherit" }}>
					<Tooltip title="Pinterest">
						<Pinterest fontSize={size} />
					</Tooltip>
				</a>

				<a
					href={`https://news.ycombinator.com/submitlink?u=${urlEncoded}&t=${titleEncoded}`}
					target="_blank"
					style={{ textDecoration: "none", color: "inherit" }}>
					<Tooltip title="Hacker news">
						<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
							width="35" height="35"
							viewBox="0 0 172 172"
							style={{ fill: "#000000" }}>
							<g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: "normal" }}><path d="M0,172v-172h172v172z" fill="none"></path><g id="original-icon" fill="#ffffff"><path d="M13.76,13.76v144.48h144.48v-144.48zM151.36,151.36h-130.72v-130.72h130.72zM144.48,27.52h-116.96v116.96h116.96zM92.88,96.32v27.52h-13.76v-27.52l-24.08,-44.72h10.9392l20.0208,35.0536l20.0208,-35.0536h10.9392z"></path></g></g>
						</svg>
					</Tooltip>
				</a>

				<Tooltip title="Copy permalink">
					<IconButton
						aria-label="copy link"
						color="inherit"
						sx={{ p: 0, height: "max-content" }}
						onClick={() => {
							navigator.clipboard.writeText(url)
						}}>
						<LinkIcon fontSize={size} />
					</IconButton>
				</Tooltip>
			</Box>

		</Box >
	)
}

export const pageQuery = graphql`
  query FetchPostInfo($id: String!, $previousId: String, $nextId: String) {
    site {
		siteMetadata {
		  title
		  author {
			  name
		  }
		}
	}

	allTagsYaml {
		nodes {
			identifier
			title
		}
	}

	next: mdx(id: { eq: $nextId }) {
		frontmatter {
			title
			date(formatString: "MMMM DD, YYYY")
			description {
				short
			}
			tags
			banner {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
		
		fields {
			defaultSlug
		}
	}

	previous: mdx(id: { eq: $previousId }) {
		fields {
			defaultSlug
		}

		frontmatter {
			title
			date(formatString: "MMMM DD, YYYY")
			description {
				short
			}
			tags
			banner {
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	}

    mdx(id: { eq: $id }) {
	  id
	  excerpt
	  body
	  headings {
		value
		depth
	  }
      frontmatter {
		title
        date(formatString: "MMMM DD, YYYY")
		description {
			short
			long
		}
        tags
        banner {
          childImageSharp {
			gatsbyImageData
          }
        }

		seoImage: banner {
			childImageSharp {
			  resize(width: 1200) {
				src
				height
				width
			  }
			}
		  }
      }
    }
  }
`;

export default Post;