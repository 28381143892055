import MuiAvatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// @ts-ignore
import React from "react";
import { Socials } from './Socials';

export interface AvatarProps {
	name: string,
	infoText?: string,
	showSocials?: boolean,
}

export const Avatar = (props: AvatarProps) => {
	return (
		<Box display="flex" alignItems="center" gap="0.8rem" >
			<MuiAvatar alt={props.name} sx={{ px: "auto" }} src="/assets/images/profile-pic.png" />
			<div>
				<Typography component="div" variant="body1" fontWeight="bold" fontSize="1rem">
					{props.name}
				</Typography>

				{props.infoText && (
					<Typography component="div" variant="body2" color="text.secondary" fontSize="0.8rem">
						{props.infoText}
					</Typography>
				)}
			</div>
			{props.showSocials && <Socials size="small" sx={{ gap: "0.5rem" }} />}
		</Box>
	)
}