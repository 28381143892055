import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { GetBioDataQuery } from "../graphqlTypes"
import { Socials } from "./Socials"

interface BioProps {
}

const Bio: React.FC<BioProps> = () => {
  const data: GetBioDataQuery = useStaticQuery(graphql`
    query GetBioData {
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `)

  if (!data.site) {
    throw new Error("couldn't fetch site data! Shouldn't happen!")
  }

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata.author

  return (
    <Paper sx={{ borderRadius: "0.5rem", p: "2rem" }}>
      <Box
        display="grid"
        alignItems="center"
        textAlign="center"
        justifyItems="center"
        gridTemplateColumns={{ xs: "1fr", sm: "1fr", md: "auto 1fr" }}
        gap="2rem"
      >
        <img
          src="/assets/images/profile-pic.png"
          width={108}
          height={108}
          style={{ borderRadius: "50%" }}
          alt="Profile picture"
        />
        <Box>
          <Typography variant="h5" fontWeight="bold" gutterBottom>{author.name}</Typography>
          <Typography variant="body1" mb="1rem">
            Software Engineer<br/>
            Talks about Kotlin, AWS and software architecture.
          </Typography>
          <Socials size="large" sx={{ mx: "auto" }} />
        </Box>
      </Box>
    </Paper>
  )
}

export default Bio
